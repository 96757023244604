/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/breakpoints";

/* Google icons */
@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0';

/*Change text in autofill textbox*/
input:-webkit-autofill
{
  -webkit-text-fill-color: var(--ion-color-secondary) !important;
}

ion-content {
  --background: #F2F2F2;
}

* {
  color: var(--ion-color-secondary);
}

.flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

:host {
  font-weight: 400;
}

ion-list {
  -webkit-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.20);
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.20);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

ion-card {
  box-shadow: rgb(0 0 0 / 18%) 0px 4px 8px;
  margin: 20px 0;
}

ion-toast {
  --background: rgba(246, 200, 45, 0.9);
  font-size: 1rem;
}

.pink-link {
  color: #ED7BA3;
  font-weight: 500;
  text-decoration: none;
}

.pink-dot {
  background: #ED7BA3;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: 30px;
}

.wrapper {
  max-width: 1000px;
  min-height: 100%;
  margin: 0 auto;
}

.down-arrow {
  margin-bottom: -6px;
}
.up-arrow {
  margin-bottom: 4px;
}

.input-error {
  background: red;
  padding: 10px;
  color: white;
}

app-user-menu {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 60px;
}

app-user-menu::-webkit-scrollbar {
  display: none;
}


@media only screen and (min-width: $laptop-breakpoint) {
  .wrapper {
    padding: 0 30px;
  }
}


